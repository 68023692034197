import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Iframe from "react-iframe"
import { Helmet } from "react-helmet";

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

import arts from "../../images/icons/Icon/Arts.png"
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import { SectionDivider, HalfColumn, TwoColumn } from "../../components/global"

const query = graphql`
  query {
    strapiTermsandconditions {
        tncText
      }
    }
`;



const TnC = () => {
  const data = useStaticQuery(query);
  return (
    <Layout>
        <Helmet>
            <title>Dili – Dili Terms and Conditions</title>
            <meta name="description" content="Please read these Terms & Conditions carefully before accessing or using our Platform. By accessing or using any part of the Platform, you agree to be bound by these Terms & Conditions." />
        </Helmet>
        <Navigation></Navigation>
        <CategoryContainer>
            <CategoryHeader>Terms and Conditions</CategoryHeader>
            <CategoryText>
                <ReactMarkdown>
                    {data.strapiTermsandconditions.tncText}
                </ReactMarkdown>
            </CategoryText>
        <Spacing />
        </CategoryContainer>
        <Footer />
    </Layout>
    )
}
  

export default TnC


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 100%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

const FormText = styled.div`
    flex-direction: column;
    margin-top: 120px;
`

const FormHeader = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: #142630;
    width: 60%;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60%;
    font-size: 20px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`
